// src/stores/UserStore.ts

import { makeAutoObservable } from "mobx";
import { User } from "firebase/auth";

class UserStore {
  email: string | null = null;
  user: User | null = null;
  loading: boolean = true;
  testEmail: string | null = null;

  constructor() {
    makeAutoObservable(this, {
      isAuthenticated: false, // Mark as computed
    });
  }

  setUser(user: User | null) {
    this.user = user;
    this.email = user?.email || null;
    console.log("UserStore - user updated to:", user);
    console.log("UserStore - email updated to:", this.email);
  }

  setLoading(value: boolean) {
    this.loading = value;
  }

  setTestEmail(value: string | null) {
    this.testEmail = value;
  }

  get isAuthenticated() {
    return this.user !== null || this.testEmail !== null;
  }
}

const userStore = new UserStore();

export default userStore;
