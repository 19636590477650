// src/config/googleConfig.ts

export const GOOGLE_CONFIG = {
  googlePicker: {
    clientId:
      "480885640389-3btb5uglbhbnfa62c9gi55m8dgso86mv.apps.googleusercontent.com",
    apiKey: "AIzaSyCP_dkUyhCnrp7hMBTfw1ZFeVK5c6yQ0UA",
    scope: ["https://www.googleapis.com/auth/drive.readonly"],
  },
};
