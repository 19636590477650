import styled from "styled-components";

interface SVGWrapperProps {
  $width?: string;
  $height?: string;
  $fill?: string;
  $padding?: string;
  $margin?: string;
}

export const SVGWrapper = styled.span<SVGWrapperProps>`
  position: relative;
  width: ${({ $width }) => $width || "64px"};
  height: ${({ $height }) => $height || "64px"};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ $padding }) => $padding || "0"};
  margin: ${({ $margin }) => $margin || "0"};

  svg {
    /* Add SVG-specific styles here */
  }
`;
