// src/config/webhooks.ts

export const WEBHOOKS = {
  RESUME_SETUP: "https://n8n.s-kode.com/webhook/RAM_setupResumeGDoc",
  DELETE_ACCOUNT: "https://n8n.s-kode.com/webhook-test/RAM_userDeleteAccount",
  SIGN_UP: "https://n8n.s-kode.com/webhook/RAM_userSignUp",
  MAKE_RESUME: "https://n8n.s-kode.com/webhook-test/RAM_make",
  UPDATE_DEFAULT_RESUME:
    "https://n8n.s-kode.com/webhook/RAM_updateDefaultBaseResume",
  DELETE_BASE_RESUME: "https://n8n.s-kode.com/webhook/RAM_deleteBaseResume",
  MAKE_DELETE: "https://n8n.s-kode.com/webhook/RAM_deleteMake",
  MAKE_COVER_LETTER: "https://n8n.s-kode.com/webhook/RAM_makeCoverLetter",
};
