// src/components/GooglePicker/utils/googlePickerLoader.ts

export const loadGoogleAPIs = (() => {
  let promise: Promise<void> | null = null;

  return () => {
    if (promise) {
      return promise;
    }

    promise = new Promise((resolve, reject) => {
      const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";
      const GIS_SDK_URL = "https://accounts.google.com/gsi/client";

      const loadScript = (src: string) => {
        return new Promise<void>((res, rej) => {
          const existingScript = document.querySelector(`script[src="${src}"]`);
          if (existingScript) {
            existingScript.addEventListener("load", () => res());
            existingScript.addEventListener("error", () =>
              rej(new Error(`Failed to load script ${src}`))
            );
            return;
          }

          const script = document.createElement("script");
          script.src = src;
          script.async = true;
          script.onload = () => res();
          script.onerror = () => rej(new Error(`Failed to load script ${src}`));
          document.body.appendChild(script);
        });
      };

      Promise.all([loadScript(GOOGLE_SDK_URL), loadScript(GIS_SDK_URL)])
        .then(() => {
          if (window.gapi) {
            window.gapi.load("picker", () => {
              console.log("gapi loaded with picker.");
              resolve();
            });
          } else {
            reject(new Error("gapi not loaded."));
          }
        })
        .catch((error) => {
          console.error("Failed to load Google APIs scripts:", error);
          reject(error);
        });
    });

    return promise;
  };
})();
