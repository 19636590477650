// src/components/primitives/Buttons.styled.tsx

import styled from "styled-components";

export const RoundedButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.main};
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  padding: 0 16px;
  height: 40px;
  margin: 0 4px;
  font-size: small;
  background-color: ${({ theme }) => theme.colors.buttonPrimary};
  border: 1px solid #ddd;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.buttonText};
  box-shadow: ${({ theme }) => theme.shadows.button};
  letter-spacing: 0.2px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
    background-color: ${({ theme }) => theme.colors.buttonPrimary};
  }

  @media (max-width: 768px) {
    max-width: 300px;
    margin: 10px auto;
  }
`;

export const SquareButton = styled.button`
  padding: 10px 16px;
  // background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.main};
  font-weight: bold;
  box-shadow: ${({ theme }) => theme.shadows.button};
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(0.8);
  }

  &:focus {
    outline: none;
  }
`;

export const TableButton = styled.button`
  background-color: ${({ theme }) => theme.colors.buttonPrimary};
  padding: 6px 10px;
  color: #ddd;
  border: solid 1px #ddd;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  font-size: x-small;
  font-weight: 700;
  text-align: center;
  box-shadow: 10px 0 40px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  max-width: 100%;
  transition: background 0.3s ease;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const TableIconButton = styled.img`
  width: auto;
  height: 28px;
  vertical-align: middle;
  padding: 0;
  transition:
    transform 0.2s ease,
    filter 0.2s ease;

  &:hover {
    transform: scale(1.1);
    filter: brightness(0.8);
    cursor: pointer;
  }
`;
