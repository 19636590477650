// src/components/primitives/HorizontalRule.styled.tsx

import React from "react";
import styled from "styled-components";

/* HORIZONTAL RULE */

export const HorizontalRule = styled.hr`
  border: 0;
  height: 0;
  opacity: 0.8;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;
