// src/services/auth/authPostMessage.ts

// ------------------------------
// SEND MESSAGE TO CONTENT SCRIPT
// ------------------------------

interface PostMessageData {
  // Define the structure of data expected, update types as needed
  [key: string]: any;
}

export function sendMessageToContentScript(data: PostMessageData): void {
  // Serialize the data into a JSON string and send it to a specific origin
  window.postMessage(JSON.stringify(data), "https://app.s-kode.com");

  // Log after the message is sent
  console.log("User login sent to AutoCV extension:", data);
}
