// src/domains/Try/store/TryStore.ts

import { makeAutoObservable } from "mobx";

class TryStore {
  resumeInput: string = "";
  selectedDoc: {
    id: string;
    title: string;
    url: string;
    mimeType: string;
    thumbnailUrl?: string;
    ownerEmail?: string;
    createdDate?: string;
    modifiedDate?: string;
  } | null = null;
  jobDescriptionInput: string = "";

  // New property to store resumePDFurl
  resumePDFurl: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  setResumeInput(value: string) {
    this.resumeInput = value;
    console.log("MobX Store - resumeInput updated to:", value);
  }

  setSelectedDoc(
    doc: {
      id: string;
      title: string;
      url: string;
      mimeType: string;
      thumbnailUrl?: string;
      ownerEmail?: string;
      createdDate?: string;
      modifiedDate?: string;
    } | null
  ) {
    this.selectedDoc = doc;
    console.log("MobX Store - selectedDoc updated to:", doc);
  }

  setJobDescriptionInput(value: string) {
    this.jobDescriptionInput = value;
    console.log("MobX Store - jobDescriptionInput updated to:", value);
  }

  // New setter for resumePDFurl
  setResumePDFurl(value: string) {
    this.resumePDFurl = value;
    console.log("MobX Store - resumePDFurl updated to:", value);
  }

  // Optional: Persist state to localStorage
  persistState() {
    localStorage.setItem(
      "tryStore",
      JSON.stringify({
        resumeInput: this.resumeInput,
        selectedDoc: this.selectedDoc,
        jobDescriptionInput: this.jobDescriptionInput,
        resumePDFurl: this.resumePDFurl, // Persist the new field
      })
    );
    console.log("MobX Store - State persisted to localStorage.");
  }

  // Optional: Load state from localStorage
  loadState() {
    const storedState = localStorage.getItem("tryStore");
    if (storedState) {
      const { resumeInput, selectedDoc, jobDescriptionInput, resumePDFurl } =
        JSON.parse(storedState);
      this.resumeInput = resumeInput;
      this.selectedDoc = selectedDoc;
      this.jobDescriptionInput = jobDescriptionInput;
      this.resumePDFurl = resumePDFurl; // Load the new field
      console.log("MobX Store - State loaded from localStorage:", {
        resumeInput,
        selectedDoc,
        jobDescriptionInput,
        resumePDFurl,
      });
    }
  }
}

const tryStore = new TryStore();
tryStore.loadState();

export default tryStore;
