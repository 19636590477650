// src/contexts/AppContext.tsx

import React from "react";
import tryStore from "../domains/Try/store/TryStore";
import makeStore from "../modules/Make/store/MakeStore";
import userStore from "../stores/UserStore";

const AppContext = React.createContext({ tryStore, makeStore, userStore });

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <AppContext.Provider value={{ tryStore, makeStore, userStore }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
