// src/components/primitives/index.ts

export * from "./Containers.styled";
export * from "./Headers.styled";
export * from "./Buttons.styled";
export * from "./Inputs.styled";
export * from "./Tables.styled";
export * from "./Modals.styled";
export * from "./HorizontalRule.styled";
export * from "./SVGWrapper.styled";
