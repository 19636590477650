// src/components/GooglePicker/GooglePicker.tsx

import React, { useEffect, useCallback, useState } from "react";
import { loadGoogleAPIs } from "./utils/googlePickerLoader";

interface GoogleChooserProps {
  children?: React.ReactNode;
  clientId: string;
  developerKey: string;
  scope?: string[];
  viewId?: string;
  origin?: string;
  onChange?: (data: any) => void;
  onAuthenticate?: (oauthToken: string) => void;
  onAuthFailed?: (response: any) => void;
  createPicker?: (google: any, oauthToken: string) => void;
  multiselect?: boolean;
  navHidden?: boolean;
  disabled?: boolean;
  mimeTypes?: string[];
  query?: string;
}

declare global {
  interface Window {
    gapi: any;
    google: any;
  }
}

export const GooglePicker: React.FC<GoogleChooserProps> = ({
  children,
  clientId,
  developerKey,
  scope = [
    // "https://www.googleapis.com/auth/drive.readonly",
    "https://www.googleapis.com/auth/metadata.readonly",
    "https://www.googleapis.com/auth/drive.file",
    // "https://www.googleapis.com/auth/documents.readonly",
  ],
  viewId = "DOCS",
  origin,
  onChange = () => {},
  onAuthenticate = () => {},
  onAuthFailed = () => {},
  createPicker,
  multiselect = false,
  navHidden = false,
  disabled = false,
  mimeTypes,
  query,
}) => {
  const [gapiLoaded, setGapiLoaded] = useState<boolean>(false);
  const [pickerLoaded, setPickerLoaded] = useState<boolean>(false);
  let tokenClient: any; // Store token client to prevent garbage collection

  const createPickerFn = useCallback(
    (oauthToken: string): void => {
      onAuthenticate(oauthToken);

      if (createPicker) {
        return createPicker(window.google, oauthToken);
      }

      const googleViewId = window.google.picker.ViewId[viewId || "DOCS"];
      const view = new window.google.picker.View(googleViewId);

      if (mimeTypes) {
        view.setMimeTypes(mimeTypes.join(","));
      }
      if (query) {
        view.setQuery(query);
      }

      if (!view) {
        throw new Error("Can't find view by viewId");
      }

      const picker = new window.google.picker.PickerBuilder()
        .addView(view)
        .setOAuthToken(oauthToken)
        .setDeveloperKey(developerKey)
        .setCallback(onChange as (data: any) => void);

      if (origin) {
        picker.setOrigin(origin);
      }

      if (navHidden) {
        picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN);
      }

      if (multiselect) {
        picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED);
      }

      picker.build().setVisible(true);
    },
    [
      createPicker,
      developerKey,
      viewId,
      mimeTypes,
      query,
      multiselect,
      navHidden,
      origin,
      onChange,
      onAuthenticate,
    ]
  );

  const signInWithGIS = useCallback((): void => {
    if (
      window.google &&
      window.google.accounts &&
      window.google.accounts.oauth2
    ) {
      tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: clientId,
        scope: scope.join(" "),
        callback: (tokenResponse: any) => {
          if (tokenResponse && tokenResponse.access_token) {
            createPickerFn(tokenResponse.access_token);
            onAuthenticate(tokenResponse.access_token);
          } else {
            console.error("No access token received.");
            onAuthFailed({ error: "No access token received." });
          }
        },
      });
      tokenClient.requestAccessToken();
    } else {
      console.error("Google Identity Services not available.");
      onAuthFailed({ error: "Google Identity Services not available." });
    }
  }, [clientId, scope, createPickerFn, onAuthenticate, onAuthFailed]);

  const onChoose = useCallback((): void | null => {
    console.log("onChoose initiated");
    if (!gapiLoaded || !pickerLoaded || disabled) {
      console.log(
        "onChoose failed due to missing Google APIs or component is disabled."
      );
      return null;
    }

    signInWithGIS();
  }, [gapiLoaded, pickerLoaded, disabled, signInWithGIS]);

  useEffect(() => {
    loadGoogleAPIs()
      .then(() => {
        setGapiLoaded(true);
        if (window.google?.picker) {
          setPickerLoaded(true);
        } else {
          const checkPicker = setInterval(() => {
            if (window.google?.picker) {
              setPickerLoaded(true);
              clearInterval(checkPicker);
            }
          }, 100);

          // Timeout after 5 seconds
          setTimeout(() => {
            if (!window.google?.picker) {
              console.error("Picker API not available after waiting.");
              onAuthFailed({
                error: "Picker API not available after waiting.",
              });
              clearInterval(checkPicker);
            }
          }, 5000);
        }
      })
      .catch((error) => {
        console.error("Failed to load Google APIs scripts:", error);
        onAuthFailed(error);
      });
  }, [onAuthFailed]);

  return (
    <div onClick={onChoose}>
      {children ? (
        children
      ) : (
        <button type="button" disabled={!gapiLoaded || !pickerLoaded}>
          {!gapiLoaded || !pickerLoaded ? "Loading..." : "Open Google Picker"}
        </button>
      )}
    </div>
  );
};
