// src/components/WebhookStatusModal/WebhookStatusModal.tsx

import React from "react";
import styled from "styled-components";
import { WebhookMessage } from "../../domains/Try/utils/webhookConfig"; // Adjust the import path as necessary
import { CyclingLoading } from "../CyclingLoading/CyclingLoading";
import { loadingMessages } from "../CyclingLoading/loadingMessages";

interface WebhookStatusModalProps {
  isOpen: boolean;
  message: WebhookMessage; // Changed from string to WebhookMessage
  onClose: () => void;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 700px; /* Increased width for iframe */
  max-height: 80%;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ModalTitle = styled.h2`
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

// Styled iframe
const Iframe = styled.iframe`
  width: 100%;
  height: 500px; /* Adjust height as needed */
  border: none;
`;

export const WebhookStatusModal: React.FC<WebhookStatusModalProps> = ({
  isOpen,
  message,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          {/* <ModalTitle>Webhook Status</ModalTitle> */}
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <ModalBody>
          {message.type === "text" && <div>{message.content}</div>}
          {message.type === "iframe" && (
            <Iframe src={message.content} title="Google Doc" />
          )}
          {/* Overlay the CyclingLoading component */}
          {/* <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <CyclingLoading messages={loadingMessages} />
          </div> */}
        </ModalBody>
      </ModalContainer>
    </ModalOverlay>
  );
};
