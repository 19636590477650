// src/config/index.ts

export { WEBHOOKS } from "./webhooks";
export { FIRESTORE_PATHS } from "./firestorePaths";
export { GOOGLE_CONFIG } from "./googleConfig";

// src/config/index.ts

import { WEBHOOKS } from "./webhooks";
import { FIRESTORE_PATHS } from "./firestorePaths";
import { GOOGLE_CONFIG } from "./googleConfig";
// Import other configuration sections as needed

export const config = {
  ...WEBHOOKS,
  ...FIRESTORE_PATHS,
  ...GOOGLE_CONFIG,
  // Spread other configuration sections here
};
