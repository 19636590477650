// src/components/primitives/Containers.styled.tsx

import React from "react";
import styled from "styled-components";

export const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 40px;
  padding-top: 0;
  margin-top: 0;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
`;

export const LoadingMessageContainer = styled.div`
  text-align: center;
  padding: 20px;
  color: #a4a4a4;
`;
