// src/config/firestorePaths.ts

export const FIRESTORE_PATHS = {
  userMakes: (email: string) => `users/${email}/userMakes`,
  userFilesFolderData: (email: string) => `users/${email}/userFiles/folderData`,
  userUsageTotalSums: (email: string) =>
    `users/${email}/userUsage/userUsageTotalSums`,
  userDoc: (email: string) => `users/${email}`,
  userBaseResumes: (email: string) => `users/${email}/userBaseResumes`,
};
